import { FC, ReactNode } from 'react';
import { SocketContext, useSocket } from '../context/SocketContext';


interface SocketProviderProps {
  children: ReactNode
}
 
const SocketProvider: FC<SocketProviderProps> = ({children}) => {
  const socket = useSocket();
  return (<SocketContext.Provider value={socket}>{ children }</SocketContext.Provider> );
};
 
export default SocketProvider;