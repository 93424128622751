import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import PageWrapper from '../components/PageWrapper';

import PrivateRoute from './private-routes';
import { IRoutes } from '../types/interfaces';
import PageLoader from '../components/loader/PageLoader';

const Login = lazy(() => import('../pages/SignIn'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));

const UnAuthenticatedRoutes = [
  {path: '/login', component: Login},
  {path: '/forgot-password', component: ForgotPassword},
  {path: '/reset-password', component: ResetPassword}
];

const router = (privateRoutes?: IRoutes[])=>createBrowserRouter(
  createRoutesFromElements(
    <>
      { UnAuthenticatedRoutes.map(({path, component: Component}, index)=>(<Route key={index} path={path} element={<Component />} />)) }
      <Route element={<PageWrapper />}>
        <Route path={'/'} element={<PrivateRoute component={privateRoutes?.[0]?.component || PageLoader } />} />
        {privateRoutes?.map(({ path, component }, index) =>
          <Route key={`${index}+${path}`} path={path} element={<PrivateRoute component={component} path={path} />} />
        )}
        <Route path={'*'} element={<PrivateRoute component={privateRoutes?.[0]?.component || PageLoader} />} />
      </Route>
    </>
  )
);


export default router;
