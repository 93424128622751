import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import LogoutIcon from '@mui/icons-material/Logout';
import { Outlet } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Laptop } from '@mui/icons-material';
import { FC, useEffect } from 'react';

import AMAppBar from './AMAppBar';
import { useAppState } from '../state/context/AppStateContext';
import { useSocket } from '../state/context/SocketContext';
import { sendNotification } from '../utils';
import { SegmentTypes, Segments } from '../types/interfaces';

interface PageWrapperProps {
}

const PageWrapper: FC<PageWrapperProps> = () => {

  const { setAuthStatus, authStatus, privateRoutes } = useAppState();
  const socket = useSocket();

  useEffect(()=>{
    socket.emit('auth', { auth: authStatus?.token });

    socket.off(Segments.ROLE).on(Segments.ROLE, (d) => {
      const data = d && typeof d === 'string' ? JSON.parse(d) : d;
      if(!data || !data.type){
        return;
      }

      switch(data.type){
        case SegmentTypes.LEAVE: {
          if(authStatus?.role !== data?.role) {
            return;
          }

          sendNotification('Leave Request', data.message);
        }
      }

      return;
    });

    socket.off('auth-failed').on('auth-failed', () => {
      setAuthStatus && setAuthStatus();
      return;
    });
  }, []);

  return (
    <Box sx={{ height: '100vh', width: '100vw', position: 'fixed', px: 2, pt: 2 }}>
      <CssBaseline />
      <AMAppBar
        pages={privateRoutes?.filter(({ isMenuPage }) => isMenuPage)}
        settings={[
          { name: 'Profile', path: '/profile' || '', icon: AccountCircleIcon },
          { name: 'My Assets', path: '/my-assets' || '', icon: Laptop },
          { name: 'Sign Out', path: setAuthStatus || '', icon: LogoutIcon },
        ]}
      />
      <Box sx={{ mt: 4, height: '100%' }}><Outlet /></Box>
    </Box>
  );
};

export default PageWrapper;
