import { Suspense, useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { CssBaseline } from '@mui/material';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.css';

import router from './router';
import ThemeSettings from './theme';
import AppStateProvider from './state/provider/AppStateProvider';
import { SnackbarProvider } from 'notistack';
import { checkNotificationStatus } from './utils';
// import NotificationWarning from './components/NotificationWarning';
import SocketProvider from './state/provider/SocketProvider';
import { useAppState } from './state/context/AppStateContext';
import PageLoader from './components/loader/PageLoader';

const RouterWrapper = () => {
  const { privateRoutes } = useAppState();

  return <RouterProvider router={router(privateRoutes)} />;
};

const Core = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <AppStateProvider>
        <ThemeSettings>
          <CssBaseline />
          <SnackbarProvider
            preventDuplicate
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          >
            <RouterWrapper />
          </SnackbarProvider>
        </ThemeSettings>
      </AppStateProvider>
    </Suspense>
  );
};

function App() {
  const [status, setStatus] = useState<boolean>();

  useEffect(() => {
    checkNotificationStatus(true).then(setStatus);
  }, []);

  return (
    <div className="App">
      {typeof status === 'undefined' ? (
        <PageLoader />
      ) : status === false ? (
        <Core />
      ) : (
        <SocketProvider>
          <Core />
        </SocketProvider>
      )}
    </div>
  );
}

export default App;
