import React, { FC, useEffect, useState } from 'react';
import { IAuth, IPropsWithChildren, IRoutes } from '../../types/interfaces';
import { getAuthStorage, setAuthStorage } from '../../utils';
import { AppStateContext } from '../context/AppStateContext';
import { routeConstants } from '../../utils/constants';
import useAxios from '../../hooks/useAxios';


const AppStateProvider: FC<IPropsWithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<IAuth | undefined>();
  const [routes, setRoutes] = useState<IRoutes[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  
  const { fetch } = useAxios();

  useEffect(() => {
    const authData = getAuthStorage();
    setAuth(authData);
  }, []);

  useEffect(()=>{
    if(!auth || !auth.token){
      setLoading(false);
      return;
    }

    setPrivateRoutes(auth.token);
  },[auth]);

  const setAuthStatus = (auth?: IAuth): void => {
    if (!auth) {
      localStorage.clear();
      setAuth(auth);
      return;
    }

    setAuth(() => {
      setAuthStorage(auth);
      return auth;
    });
  };

  const setPrivateRoutes = (token: string): void => {
    setLoading(true);
    fetch({ url: '/user/permissions', method: 'GET' }, token)?.then(({ data }) => {
      setLoading(false);
      if (!data || !data.length) return;

      const routeList = routeConstants.filter(route => data?.includes(`/${route.path.split('/')[1]}`));
      setRoutes(routeList);
    });
  };

  const providerValue = {
    authStatus: auth,
    setAuthStatus,
    privateRoutes: routes
  };
  return loading ? <></> : <AppStateContext.Provider value={providerValue}>{children}</AppStateContext.Provider>;
};

export default AppStateProvider;