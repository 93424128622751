import { Slide, ThemeOptions } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Colors, SizeUnits } from '../types/interfaces';


declare module '@mui/material/styles' {
  interface CustomTheme {
    colors?:  Colors;
    fontSizes: SizeUnits;
  }
  
  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
}

interface ColorSchema {
  primaryColor: string;
  secondaryColor: string;
  buttonText: string;
  buttonIcon: string;
  buttonBackground: string;
  primaryFont: string;
  secondaryFont: string;
}

interface ThemeSettingsProps {
  colors?: ColorSchema;
  children: ReactNode;
}

const ThemeSettings: FC<ThemeSettingsProps> = ({ colors, children }) => {
  const [currentColors, setCurrentColors] = useState<ColorSchema>({
    primaryColor: '#284d8a',
    secondaryColor: '#3363B2',
    buttonBackground: '#284d8a',
    buttonIcon: '#ebe9f0',
    buttonText: '#ebe9f0',
    primaryFont: 'roboto',
    secondaryFont: 'roboto',
  });

  useEffect(() => {
    setCurrentColors(currentColors);
  }, []);

  useEffect(() => {
    setCurrentColors(currentColors);
  }, [colors]);

  const theme = (themeOptions?: ThemeOptions) => createTheme(themeOptions);

  const themeOptions = {
    palette: {
      primary: { main: currentColors.primaryColor },
      secondary: { main: currentColors.secondaryColor },
    },
    overrides: {
      MuiStepIcon: {
        root: {
          '&$completed': {
            color: 'pink',
          },
          '&$active': {
            color: 'red',
          },
        },
        active: {},
        completed: {},
      },
    },
    colors: {
      primary: {
        '5': `${currentColors?.primaryColor}05`,
        '10': `${currentColors?.primaryColor}10`,
        '20': `${currentColors?.primaryColor}20`,
        '40': `${currentColors?.primaryColor}40`,
        '60': `${currentColors?.primaryColor}60`,
        '80': `${currentColors?.primaryColor}80`,
        default: `${currentColors?.primaryColor}`,
      },
      secondary: {
        '5': `${currentColors?.secondaryColor}05`,
        '10': `${currentColors?.secondaryColor}10`,
        '20': `${currentColors?.secondaryColor}20`,
        '40': `${currentColors?.secondaryColor}40`,
        '60': `${currentColors?.secondaryColor}60`,
        '80': `${currentColors?.secondaryColor}80`,
        default: `${currentColors?.secondaryColor}`,
      },
      buttonText: {
        '5': `${currentColors?.buttonText}05`,
        '10': `${currentColors?.buttonText}10`,
        '20': `${currentColors?.buttonText}20`,
        '40': `${currentColors?.buttonText}40`,
        '60': `${currentColors?.buttonText}60`,
        '80': `${currentColors?.buttonText}80`,
        default: `${currentColors?.buttonText}`,
      },
      buttonIcon: {
        '5': `${currentColors?.buttonIcon}05`,
        '10': `${currentColors?.buttonIcon}10`,
        '20': `${currentColors?.buttonIcon}20`,
        '40': `${currentColors?.buttonIcon}40`,
        '60': `${currentColors?.buttonIcon}60`,
        '80': `${currentColors?.buttonIcon}80`,
        default: `${currentColors?.buttonIcon}`,
      },
      buttonBackground: {
        '5': `${currentColors?.buttonBackground}05`,
        '10': `${currentColors?.buttonBackground}10`,
        '20': `${currentColors?.buttonBackground}20`,
        '40': `${currentColors?.buttonBackground}40`,
        '60': `${currentColors?.buttonBackground}60`,
        '80': `${currentColors?.buttonBackground}80`,
        default: `${currentColors?.buttonBackground}`,
      },
      photoBlue: {
        '5': '#C1E0E705',
        '10': '#C1E0E710',
        '20': '#C1E0E720',
        '40': '#C1E0E740',
        '60': '#C1E0E760',
        '80': '#C1E0E780',
        default: '#C1E0E7',
      },
      deepSteelGrey: {
        '5': '#3B3E425',
        '10': '#3B3E4210',
        '20': '#3B3E4220',
        '40': '#3B3E4240',
        '60': '#3B3E4260',
        '80': '#3B3E4280',
        default: '#3B3E42',
      },
      lightStaleGrey: {
        '5': '#77889905',
        '10': '#77889910',
        '20': '#77889920',
        '40': '#77889940',
        '60': '#77889960',
        '80': '#77889980',
        default: '#778899',
      },
      aliceBlue: {
        '5': '#F0F8FF05',
        '10': '#F0F8FF10',
        '20': '#F0F8FF20',
        '40': '#F0F8FF40',
        '60': '#F0F8FF60',
        '80': '#F0F8FF80',
        default: '#F0F8FF',
      },
      lightGrey: {
        '5': '#D3D3D305',
        '10': '#D3D3D310',
        '20': '#D3D3D320',
        '40': '#D3D3D340',
        '60': '#D3D3D360',
        '80': '#D3D3D380',
        default: '#D3D3D3',
      },
      almond: {
        '5': '#EFDECD05',
        '10': '#EFDECD10',
        '20': '#EFDECD20',
        '40': '#EFDECD40',
        '60': '#EFDECD60',
        '80': '#EFDECD80',
        default: '#EFDECD',
      },
      davyGrey: {
        '5': '#55555505',
        '10': '#55555510',
        '20': '#55555520',
        '40': '#55555540',
        '60': '#55555560',
        '80': '#55555580',
        default: '#555555',
      },
      onyx: {
        '5': '#35383905',
        '10': '#35383910',
        '20': '#35383920',
        '40': '#35383940',
        '60': '#35383960',
        '80': '#35383980',
        default: '#353839',
      },
      charcoal: {
        '5': '#36454F05',
        '10': '#36454F10',
        '20': '#36454F20',
        '40': '#36454F40',
        '60': '#36454F60',
        '80': '#36454F80',
        default: '#36454F',
      },
      cadet: {
        '5': '#53687205',
        '10': '#53687210',
        '20': '#53687220',
        '40': '#53687240',
        '60': '#53687260',
        '80': '#53687280',
        default: '#536872',
      },
      white: {
        default: '#FFFFFF',
      },
      black: {
        default: '#000000',
      },
      error: {
        '5': '#E32B2405',
        '10': '#E32B2410',
        '20': '#E32B2420',
        '40': '#E32B2440',
        '60': '#E32B2460',
        '80': '#E32B2480',
        default: '#E32B24',
      },
      warning: {
        '5': '#FFC10705',
        '10': '#FFC10710',
        '20': '#FFC10720',
        '40': '#FFC10740',
        '60': '#FFC10760',
        '80': '#FFC10780',
        default: '#FFC107',
      },
      success: {
        '5': '#18BB0C05',
        '10': '#18BB0C10',
        '20': '#18BB0C20',
        '40': '#18BB0C40',
        '60': '#18BB0C60',
        '80': '#18BB0C80',
        default: '#18BB0C',
      },
      lightBlue: {
        '5': '#2680EB5',
        '10': '#2680EB10',
        '20': '#2680EB20',
        '40': '#2680EB40',
        '60': '#2680EB60',
        '80': '#2680EB80',
        default: '#2680EB',
      },
    },
    fontSizes: {
      h1: '34px',
      h2: '22px',
      h3: '20px',
      xl: '18px',
      large: '16px',
      medium: '14px',
      small: '12px',
    },
  };

  return (
    <ThemeProvider theme={theme(themeOptions)}>
      <SnackbarProvider
        maxSnack={1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        TransitionComponent={Slide}
        autoHideDuration={3000}
      >
        {children}
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default ThemeSettings;
