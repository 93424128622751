/* eslint-disable @typescript-eslint/no-explicit-any */
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import dayjs, { Dayjs } from 'dayjs';

import { IAuth, Obj, SortOrder } from '../types/interfaces';

const KEY = 'session';

export const isTokenExpired = (token?: string): boolean => {
  try {
    if (!token) {
      return true;
    }

    const decodedToken = <Obj>jwtDecode(token);
    const currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log('Token expired.');
      return true;
    }

    return false;
  } catch (err) {
    console.log(err);
    return true;
  }
};

export const setAuthStorage = (auth: IAuth): void => {
  try {
    localStorage.setItem(KEY, JSON.stringify(auth));
    return;
  } catch (err) {
    return;
  }
};

export const getAuthStorage = (): IAuth | undefined => {
  try {
    const auth = localStorage.getItem(KEY);
    if (!auth) {
      return;
    }

    const result = JSON.parse(auth);
    return result;
  } catch (err) {
    return;
  }
};

export const capitalize = (str: string): string => {
  if (!str || !str.trim()) {
    return str;
  }

  const s = str.toLowerCase().replace(/_/gi, ' ');
  return s
    .split(' ')
    .map((st) => st.charAt(0).toUpperCase() + st.slice(1))
    .join(' ');
};

export function clone<T = Obj>(data: T): T {
  return JSON.parse(JSON.stringify(data));
}

export const formatDate = (date: string | Date, requiredString = false, requiredTime = false) => {
  if (!date) {
    return '';
  }

  if (requiredTime) {
    return moment.utc(date).format('DD/MM/YYYY hh:mm:ss A');
  }

  if (requiredString) {
    const now = moment.utc();
    const days = now.diff(date, 'days');

    switch (days) {
      case 0:
        return 'Today';

      default:
        return moment.utc(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }
  }

  return moment.utc(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
};

export const convertDBDateFormatForDatePicker = (date: string | Date | number) => dayjs(moment.utc(date).toDate());

export const validateNumber = (val: string, limit: number | number[]) => {
  if (typeof limit !== 'number') {
    return limit.includes(Number(val)) ? val : '';
  }

  const numberRegex = /^[0-9]+[.]*[0-9]*$/;
  if (!val || !numberRegex.test(val)) return '';
  if (Number(val) > limit) return '';
  return val?.match(/^-?\d+(?:\.\d{0,2})?/)?.[0] || '';
};

export const disableWeekends = (date: Dayjs): boolean => {
  return date.day() === 0 || date.day() === 6;
};

export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const textA = String(a[orderBy])?.toUpperCase();
  const textB = String(b[orderBy])?.toUpperCase();

  if (textB < textA) {
    return -1;
  }
  if (textB > textA) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: SortOrder,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

export const sendNotification = (title: string, message: string, url?: string, iconUrl?: string) => {
  const notification = new Notification(title, {
    icon: iconUrl || 'https://portal.adroitminds.com/images/favicon.png',
    body: `${message}`,
    vibrate: 2,
  });

  if (!url) {
    return;
  }

  notification.onclick = () =>
    function () {
      window.open('https://portal.adroitminds.com/leave-requests');
    };
};

export function checkNotificationStatus(reloadRequired = false): Promise<boolean> {
  return new Promise((resolve) => {
    if (!('Notification' in window)) {
      alert('This browser does not support system notifications!');
      return resolve(false);
    } else if (Notification.permission === 'granted') {
      return resolve(true);
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission((permission) => {
        if (permission === 'granted' && reloadRequired) {
          location.reload();
          return;
        }

        if (permission === 'granted' && !reloadRequired) {
          location.reload();
          return resolve(true);
        }

        resolve(false);
      });
    } else if (Notification.permission === 'denied') {
      resolve(false);
    }

    resolve(false);
  });
}

export const getFirstLetters = (category: string): string => {
  return category
    .split('_')
    .map((t) => t.charAt(0).toUpperCase())
    .join('');
};

export const isValidAadharNumber = (aadharNumber: string) => {
  const regex = new RegExp(/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/);

  if (aadharNumber == null) {
    return false;
  }
  if (regex.test(aadharNumber.toString()) == true) {
    return true;
  } else {
    return false;
  }
};

export const isValidPanCardNumber = (panNumber: string) => {
  const regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);

  if (panNumber === null) {
    return false;
  }

  if (regex.test(panNumber.toString()) === true) {
    return true;
  } else {
    return false;
  }
};

export const isValid_IFSC_Code = (ifsc: string) => {
  const regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);

  if (ifsc == null) {
    return false;
  }

  if (regex.test(ifsc) == true) {
    return true;
  }

  return false;
};

export const calculateAge = (dobString: string): number => {
  const dob = new Date(dobString);
  const ageDiff = Date.now() - dob.getTime();
  const ageDate = new Date(ageDiff);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const findLengthAndReturnStar = (str:string) => {
  const length = str.length;
  if(!length){
    return;
  }

  let star = '';
  for (let i = 0; i < length; i++) {
    star = star + 'X';
  }
  return star;
};