import { connect } from 'socket.io-client';
import { createContext, useContext } from 'react';

import { BASE_URL } from '../../config';

const url = BASE_URL?.replace('/api','') || '';
const socket = connect(url, {
  path: '/socket.io',
  transports: ['websocket'],
});

export const SocketContext = createContext(socket);

export const useSocket = () => {
  const s = useContext<typeof socket>(SocketContext);
  if (!s) {
    throw new Error('useSocket must be used within SocketProvider');
  }

  return s;
};
