/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig } from 'axios';
import { useEffect, useState } from 'react';

import { isTokenExpired } from '../utils';
import { useAppState } from '../state/context/AppStateContext';
import { BASE_URL } from '../config';

const httpClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const useAxios = <T,>(reqParams?: AxiosRequestConfig) => {
  const [response, setResponse] = useState<T | undefined>(undefined);
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(!!reqParams);

  const { authStatus, setAuthStatus } = useAppState();

  const fetch = (params: AxiosRequestConfig, jwtToken?: string) => {
    if (!httpClient) {
      return;
    }

    const reqInterceptor = httpClient.interceptors.request.use(async (req) => {
      const token = jwtToken ?? authStatus?.token;
      req.headers.Authorization = `Bearer ${token}`;
      const isExpired = isTokenExpired(token);
      if (!isExpired) {
        return req;
      }

      setAuthStatus && setAuthStatus();
      return req;
    });

    setLoading(true);
    return httpClient
      .request(params)
      .then((response) => {
        setResponse(response?.data);
        return response?.data;
      })
      .catch((error) => {
        setError(error?.response?.data);
        return error?.response?.data;
      })
      .finally(() => {
        setLoading(false);
        httpClient && httpClient.interceptors.request.eject(reqInterceptor);
      });
  };

  useEffect(() => {
    if (!reqParams) {
      return;
    }

    fetch(reqParams);
  }, [reqParams?.url]);

  return { response, error, loading, fetch };
};

export default useAxios;
