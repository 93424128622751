/* eslint-disable @typescript-eslint/no-empty-function */
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIconProps,
  useTheme,
} from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

import Logo from './Logo';
import { useAppState } from '../state/context/AppStateContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnyFunction, IRoutes } from '../types/interfaces';
import {
  ComponentType,
  FC,
  Fragment,
  MouseEvent,
  ReactNode,
  useEffect,
  useState,
} from 'react';

interface AMAppBarProps {
  children?: ReactNode;
  pages?: IRoutes[];
  settings?: Array<{
    name: string;
    path: string | AnyFunction;
    icon: ComponentType<SvgIconProps>;
  }>;
}

interface MobileDrawerMenuProps {
  selectedPath: string;
  pages: IRoutes[];
  onToggle?: (open: boolean) => void;
}

const MobileDrawerMenu: FC<MobileDrawerMenuProps> = ({
  pages,
  onToggle,
  selectedPath,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleClick = (path?: string) => {
    onToggle && onToggle(false);
    path && navigate(path);
  };

  return (
    <Box sx={{ width: 250 }} role="presentation">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={60}
      >
        <Logo sx={{ width: 'auto', height: 50, padding: '5px' }} />
      </Box>
      <List>
        {pages.map(({ icon: Icon, name, path }, index) => (
          <Fragment key={`${name}-${index}`}>
            <Divider />
            <ListItem
              sx={{
                color:
                  path === selectedPath
                    ? theme.colors?.primary?.default
                    : 'unset',
              }}
              disablePadding
              onClick={() => handleClick(path)}
            >
              <ListItemButton>
                <ListItemIcon>
                  <Icon
                    sx={{
                      color:
                        path === selectedPath
                          ? theme.colors?.primary?.default
                          : 'unset',
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary={name} />
              </ListItemButton>
            </ListItem>
          </Fragment>
        ))}
      </List>
      <Divider />
    </Box>
  );
};

const AMAppBar: FC<AMAppBarProps> = ({ pages = [], settings = [] }) => {
  const theme = useTheme();
  const { authStatus } = useAppState();
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [selectedPath, setSelectedPath] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    if (location?.pathname) {
      const pathname = location.pathname.split('/');
      setSelectedPath('/' + (pathname[1]?.length ? pathname[1] : 'time-sheet'));
    }
  }, [location]);

  const toggleDrawer = (open: boolean) => {
    setIsDrawerOpen(open);
  };

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (path = '') => {
    path && navigate(path);
    setSelectedPath(path);
  };

  const handleCloseUserMenu = (path?: string | AnyFunction) => {
    setAnchorElUser(null);

    if (path && typeof path === 'function') {
      path();
      return;
    }

    if (path && typeof path === 'string') {
      navigate(path);
      return;
    }
  };

  const menuItems = (pages: IRoutes[]) => {
    const dropdown: Record<string, IRoutes[]> = {};
    const result = (pages || []).map((page) => {
      if (page.parent) {
        dropdown[page.parent] = dropdown[page.parent] || [];
        dropdown[page.parent]?.push(page);
        return null;
      }

      return (
        <Button
          key={page.name}
          onClick={() => handleCloseNavMenu(page.path)}
          sx={{
            my: 2,
            color:
              page.path === selectedPath
                ? theme.colors?.white?.default
                : theme.colors?.lightGrey?.default,
            display: 'block',
          }}
        >
          {page.name}
        </Button>
      );
    });

    const dropDownItems = Object.keys(dropdown).map((key) => {
      return (
        <PopupState
          variant='popover'
          key={key}
          popupId={key.replace(/\s/gi, '-')}
        >
          {(popupState) => (
            <>
              <Button
                sx={{
                  my: 2,
                  color: dropdown[key].find(
                    (page) => page.path === selectedPath
                  )
                    ? theme.colors?.white?.default
                    : theme.colors?.lightGrey?.default,
                  display: 'block',
                }}
                variant='text'
                {...bindTrigger(popupState)}
              >
                {key}
              </Button>
              <Menu {...bindMenu(popupState)}>
                {dropdown[key].map((item) => (
                  <MenuItem
                    key={`${key}-${item.name}`}
                    className='text-uppercase'
                    onClick={() => {
                      popupState.close();
                      setTimeout(() => {
                        handleCloseNavMenu(item.path);
                      }, 500);
                    }}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
        </PopupState>
      );
    });
    return [...result, ...dropDownItems];
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          borderRadius: '10px',
          background: theme.colors?.primary.default,
          width: '100%',
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Logo
              sx={{
                display: {
                  xs: 'none',
                  md: 'flex',
                  width: 'auto',
                  padding: '5px',
                  borderRadius: '5px',
                  background: '#fff',
                },
                mr: 3,
              }}
            />
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="adroitminds"
                aria-controls="menu-web-app-bar"
                aria-haspopup="true"
                onClick={() => toggleDrawer(!isDrawerOpen)}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <Logo
                sx={{
                  width: 'unset',
                  borderRadius: '5px',
                  background: '#fff',
                  padding: '5px',
                }}
              />
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {menuItems(pages)}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar>{authStatus?.name?.charAt(0)}</Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-user-app-bar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={() => handleCloseUserMenu()}
              >
                {(settings || []).map((setting) => (
                  <MenuItem
                    key={setting.name}
                    onClick={() => handleCloseUserMenu(setting.path)}
                  >
                    <ListItemIcon>
                      {setting.icon ? <setting.icon fontSize="small" /> : null}
                    </ListItemIcon>
                    <Typography textAlign="center">{setting.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <SwipeableDrawer
        sx={{ display: { xs: 'block', md: 'none' } }}
        anchor="left"
        open={isDrawerOpen}
        onClose={() => toggleDrawer(false)}
        onOpen={() => { }}
      >
        <MobileDrawerMenu
          selectedPath={selectedPath}
          pages={pages}
          onToggle={toggleDrawer}
        />
      </SwipeableDrawer>
    </>
  );
};

export default AMAppBar;
