import { FC, LazyExoticComponent } from 'react';
import { Navigate } from 'react-router-dom';

import { useAppState } from '../state/context/AppStateContext';
import { Role } from '../types/enum';
import { IPropsWithChildren } from '../types/interfaces';

interface Props {
  component: React.ComponentType | LazyExoticComponent<FC<IPropsWithChildren>> ;
  restrictedRoles?: Role[]
  path?: string;
}

export const PrivateRoute: React.FC<Props> = ({ component: RouteComponent, restrictedRoles }) => {
  const { authStatus } = useAppState();

  if (authStatus && (!restrictedRoles || !restrictedRoles?.length)) {
    return <RouteComponent />;
  }

  if (authStatus && restrictedRoles && restrictedRoles?.length) {
    return restrictedRoles.includes(authStatus.role as unknown as Role) ? <Navigate to="/" /> : <RouteComponent />;
  }

  return <Navigate to="/login" />;
};

export default PrivateRoute;